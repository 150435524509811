import React from 'react'
import { Helmet } from "react-helmet"
import Layout from '../components/Layout'
import Header from '../components/Header'
import Intro from '../components/homepage/Intro'
import Products from '../components/homepage/Products'
import OurStory from '../components/homepage/OurStory'
import Footer from '../components/Footer'

class IndexPage extends React.Component{
    render(){
        return(
            <Layout>
                <Helmet>
                <meta charset="utf-8"/>
                <title>WALTZ | Dobrodošli</title>
                <meta name="description" content="Ćatić company DOO, savremeno organizovana i tržišno orjentisana kompanija koja neprestano ulaže u kvalitet i razvoj. Najsavremenija tehnologija, jedinstvena receptura, ispunjavanje visokih svetskih zahteva i standarda za bezbednost hrane je osnova politike Ćatić kompanije."/>
                <meta name="image" content="https://waltz.rs/waltz_seo_image.jpg"/>
                <meta itemprop="name" content="WALTZ | Dobrodošli"/>
                <meta itemprop="description" content="Ćatić company DOO, savremeno organizovana i tržišno orjentisana kompanija koja neprestano ulaže u kvalitet i razvoj. Najsavremenija tehnologija, jedinstvena receptura, ispunjavanje visokih svetskih zahteva i standarda za bezbednost hrane je osnova politike Ćatić kompanije."/>
                <meta itemprop="image" content="https://waltz.rs/waltz_seo_image.jpg"/>
                <meta name="og:title" content="WALTZ | Dobrodošli"/>
                <meta name="og:description" content="Ćatić company DOO, savremeno organizovana i tržišno orjentisana kompanija koja neprestano ulaže u kvalitet i razvoj. Najsavremenija tehnologija, jedinstvena receptura, ispunjavanje visokih svetskih zahteva i standarda za bezbednost hrane je osnova politike Ćatić kompanije."/>
                <meta name="og:image" content="https://waltz.rs/waltz_seo_image.jpg"/>
                <meta name="og:url" content="https://waltz.rs"/>
                <meta name="og:site_name" content="WALTZ.rs"/>
                <meta name="og:locale" content="sr"/>
                <meta name="og:type" content="website"/>
                </Helmet>
              <Header />
              <div id="home_page">
              <Intro />
              <Products />
              <OurStory />
              <Footer />
              </div>
            </Layout>
        );
    }
}
  export default IndexPage;
  