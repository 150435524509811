import React from 'react'

class OurStory extends React.Component{
    render(){
        return(
            <section id="our_story" className="section our-story">
                <div className="container">
                    <div className="title-box">
                        <h1 className="title"> <span className="zigzag"></span> WALTZ PRIČA <span className="zigzag"></span></h1>
                    </div>{/*TITLE BOX END */}

                    <div className="content">
                        <p className="text">Ćatić company DOO, savremeno organizovana i tržišno orjentisana kompanija koja neprestano 
                        ulaže u kvalitet i razvoj. Najsavremenija tehnologija, jedinstvena receptura, ispunjavanje visokih svetskih 
                        zahteva i standarda za bezbednost hrane je osnova politike Ćatić kompanije.</p>
                        
                        <div className="columns">
                            <div className="column is-4 padding-0">
                                <div className="inner">
                                    <span className="icon years"></span>
                                    <div className="info">
                                        <h2 className="title">33 GODINE</h2>
                                        <h6 className="subtitle">Uspešnog poslovanja</h6>
                                    </div> 
                                </div>
                            </div>

                            <div className="column is-4 padding-0">
                                <div className="inner">
                                    <span className="icon countries"></span>
                                    <div className="info">
                                        <h2 className="title">10 ZEMALJA</h2>
                                        <h6 className="subtitle">Zastupljenost proizvoda</h6>
                                    </div> 
                                </div>
                            </div>

                            <div className="column is-4 padding-0">
                                <div className="inner">
                                    <span className="icon employees"></span>
                                    <div className="info">
                                        <h2 className="title">130</h2>
                                        <h6 className="subtitle">Zaposlenih</h6>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>{/*CONTENT END */}
                </div>{/*CONTAINER END */}
            </section>/* SECTION OUR STORY END */
        );
    }
}

export default OurStory