import React from "react"
import Link from "gatsby-link"
import Swiper from "react-id-swiper"

import waltz_krem_kakao from "../../images/products/waltz-krem-kakao.jpg"
import waltz_krem_mlecni from "../../images/products/waltz-krem-mlecni.jpg"
import waltz_krem_mesavina from "../../images/products/waltz-krem-mesavina.jpg"
import sweet_krem_kakao from "../../images/products/sweet-krem-kakao.jpg"
import sweet_krem_mesavina from "../../images/products/sweet-krem-mesavina.jpg"

import cream_rolls_jagoda from "../../images/products/cream-rolls-jagoda.jpg"
import cream_rolls_cokolada from "../../images/products/cream-rolls-cokolada.jpg"
import cream_rolls_vanila from "../../images/products/cream-rolls-vanila.jpg"
import cream_rolls_ljesnjak from "../../images/products/cream-rolls-ljesnjak.jpg"
import cream_rolls_pomorandza from "../../images/products/cream-rolls-pomorandza.jpg"

import sweet_bakery_kakao from "../../images/products/sweet-bakery-kakao.jpg"
import sweet_bakery_mesavina from "../../images/products/sweet-bakery-mesavina.jpg"
import waltz_bakery_mesavina from "../../images/products/waltz-bakery-mesavina.jpg"
import waltz_bakery_mesavina1 from "../../images/products/waltz-bakery-mesavina1.jpg"
import waltz_bakery_kakao from "../../images/products/waltz-bakery-kakao.jpg"

import waltz_blok from "../../images/products/waltz-blok.jpg"

import waltz_krem_rolls_ljesnjak from "../../images/products/waltz-krem-rolls-ljesnjak.jpg"
import waltz_krem_rolls_cokoladni from "../../images/products/waltz-krem-rolls-cokoladni.jpg"
import waltz_krem_rolls_mlecni from "../../images/products/waltz-krem-rolls-mlecni.jpg"
import waltz_krem_rolls_narandza from "../../images/products/waltz-krem-rolls-narandza.jpg"

import waltz_roll_jagoda from "../../images/products/waltz-roll-jagoda.jpg"
import waltz_roll_cokolada from "../../images/products/waltz-roll-cokolada.jpg"
import waltz_roll_ljesnjak from "../../images/products/waltz-roll-ljesnjak.jpg"
import waltz_roll_kokos from "../../images/products/waltz-roll-kokos.jpg"
import waltz_roll_mlecni from "../../images/products/waltz-roll-mlecni.jpg"

import waltz_kore_1 from "../../images/products/waltz_kore_za_tortu_1.jpg"
import waltz_kore_2 from "../../images/products/waltz_kore_za_tortu_2.jpg"
import waltz_kore_3 from "../../images/products/waltz_kore_za_tortu_3.jpg"

import waltz_roll_mini_1 from "../../images/products/waltz_roll_mini_1.jpg"
import waltz_roll_mini_2 from "../../images/products/waltz_roll_mini_2.jpg"
import waltz_roll_mini_3 from "../../images/products/waltz_roll_mini_3.jpg"
import waltz_roll_mini_4 from "../../images/products/waltz_roll_mini_4.jpg"

import cream_rolls_slika1 from "../../images/products/novo-cream-rolls1.jpg"
import cream_rolls_slika2 from "../../images/products/novo-cream-rolls2.jpg"
import cream_rolls_slika3 from "../../images/products/novo-cream-rolls3.jpg"
import cream_rolls_slika4 from "../../images/products/novo-cream-rolls4.jpg"

import waltz_roll_slika1 from "../../images/products/novo-waltz-rolls1.jpg"
import waltz_roll_slika2 from "../../images/products/novo-waltz-rolls2.jpg"
import waltz_roll_slika3 from "../../images/products/novo-waltz-rolls3.jpg"
import waltz_roll_slika4 from "../../images/products/novo-waltz-rolls4.jpg"

import cocoa_cake_slika1 from "../../images/products/cocoa_cake_slika1.jpg"
import cocoa_cake_slika2 from "../../images/products/cocoa_cake_slika2.jpg"
import cocoa_cake_slika3 from "../../images/products/cocoa_cake_slika3.jpg"
import cocoa_cake_slika4 from "../../images/products/cocoa_cake_slika4.jpg"
//import cocoa_cake_slika5 from "../../images/products/cocoa_cake_slika5.jpg"
import cocoa_cake_slika6 from "../../images/products/cocoa_cake_slika6.jpg"
import cocoa_cake_slika7 from "../../images/products/cocoa_cake_slika7.jpg"

import crunchy_rolls_slika1 from "../../images/products/crunchy_rolls_slika1.jpg"
import crunchy_rolls_slika2 from "../../images/products/crunchy_rolls_slika2.jpg"

class Products extends React.Component {
  render() {
    const randomAutoSlider1 = Math.floor(Math.random() * 4000 + 2000)
    const randomAutoSlider2 = Math.floor(Math.random() * 4000 + 2000)
    const randomAutoSlider3 = Math.floor(Math.random() * 4000 + 2000)
    const randomAutoSlider4 = Math.floor(Math.random() * 4000 + 2000)
    const randomAutoSlider5 = Math.floor(Math.random() * 4000 + 2000)
    const randomAutoSlider6 = Math.floor(Math.random() * 4000 + 2000)
    const randomAutoSlider7 = Math.floor(Math.random() * 4000 + 2000)
    const randomAutoSlider8 = Math.floor(Math.random() * 4000 + 2000)
    const randomAutoSlider9 = Math.floor(Math.random() * 4000 + 2000)
    const randomAutoSlider10 = Math.floor(Math.random() * 4000 + 2000)
    const randomAutoSlider11 = Math.floor(Math.random() * 4000 + 2000)

    const swiperSlider1 = {
      spaceBetween: 0,
      autoplay: {
        delay: randomAutoSlider1,
        disableOnInteraction: false,
      },
      loop: true,
    }

    const swiperSlider2 = {
      spaceBetween: 0,
      autoplay: {
        delay: randomAutoSlider2,
        disableOnInteraction: false,
      },
      loop: true,
    }

    const swiperSlider3 = {
      spaceBetween: 0,
      autoplay: {
        delay: randomAutoSlider3,
        disableOnInteraction: false,
      },
      loop: true,
    }

    const swiperSlider4 = {
      spaceBetween: 0,
      autoplay: {
        delay: randomAutoSlider4,
        disableOnInteraction: false,
      },
      loop: true,
    }

    const swiperSlider5 = {
      spaceBetween: 0,
      autoplay: {
        delay: randomAutoSlider5,
        disableOnInteraction: false,
      },
      loop: true,
    }

    const swiperSlider6 = {
      spaceBetween: 0,
      autoplay: {
        delay: randomAutoSlider6,
        disableOnInteraction: false,
      },
      loop: true,
    }

    const swiperSlider7 = {
      spaceBetween: 0,
      autoplay: {
        delay: randomAutoSlider7,
        disableOnInteraction: false,
      },
      loop: true,
    }

    const swiperSlider8 = {
      spaceBetween: 0,
      autoplay: {
        delay: randomAutoSlider8,
        disableOnInteraction: false,
      },
      loop: true,
    }

    const swiperSlider9 = {
      spaceBetween: 0,
      autoplay: {
        delay: randomAutoSlider9,
        disableOnInteraction: false,
      },
      loop: true,
    }

    const swiperSlider10 = {
      spaceBetween: 0,
      autoplay: {
        delay: randomAutoSlider10,
        disableOnInteraction: false,
      },
      loop: true,
    }

    const swiperSlider11 = {
      spaceBetween: 0,
      autoplay: {
        delay: randomAutoSlider11,
        disableOnInteraction: false,
      },
      loop: true,
    }

    const swiperSlider12 = {
      spaceBetween: 0,
      autoplay: {
        delay: randomAutoSlider10,
        disableOnInteraction: false,
      },
      loop: true,
    }

    return (
      <section
        id="proizvodi"
        className="hero products"
        style={{ backgroundColor: "#ed1b23" }}
      >
        <div className="container padding-0 margin-0">
          <div className="columns margin-0 is-multiline">
            <Link to="/crunchy-rolls" className="column is-6 padding-0">
              <Swiper {...swiperSlider12}>
                <div>
                  <img
                    src={crunchy_rolls_slika1}
                    alt="Crunchy rolls - vanilla"
                  />
                </div>
                <div>
                  <img src={crunchy_rolls_slika2} alt="Crunchy rolls - coco" />
                </div>
              </Swiper>
            </Link>
            <Link to="/cocoa-waltz-cake" className="column is-6 padding-0">
              <Swiper {...swiperSlider11}>
                <div>
                  <img
                    src={cocoa_cake_slika1}
                    alt="Cocoa Waltz Cake - Cocoa Filling"
                  />
                </div>
                <div>
                  <img
                    src={cocoa_cake_slika2}
                    alt="Cocoa Waltz Cake - Cocoa Filling"
                  />
                </div>
                <div>
                  <img
                    src={cocoa_cake_slika3}
                    alt="Cocoa Waltz Cake - Cocoa Filling"
                  />
                </div>
                <div>
                  <img
                    src={cocoa_cake_slika4}
                    alt="Cocoa Waltz Cake - Cocoa Filling"
                  />
                </div>
                <div>
                  <img
                    src={cocoa_cake_slika6}
                    alt="Cocoa Waltz Cake - Cocoa Filling"
                  />
                </div>
                <div>
                  <img
                    src={cocoa_cake_slika7}
                    alt="Cocoa Waltz Cake - Cocoa Filling"
                  />
                </div>
              </Swiper>
            </Link>
            <Link to="/waltz-cream-rolls" className="column is-6 padding-0">
              <Swiper {...swiperSlider9}>
                <div>
                  <img src={cream_rolls_slika1} alt="Waltz CreamRolls Jagoda" />
                </div>
                <div>
                  <img
                    src={cream_rolls_slika2}
                    alt="Waltz CreamRolls Čokolada"
                  />
                </div>
                <div>
                  <img src={cream_rolls_slika3} alt="Waltz CreamRolls Lešnik" />
                </div>
                <div>
                  <img src={cream_rolls_slika4} alt="Waltz CreamRolls" />
                </div>
              </Swiper>
            </Link>
            <Link to="/waltz-roll-choko" className="column is-6 padding-0">
              <Swiper {...swiperSlider10}>
                <div>
                  <img
                    src={waltz_roll_slika1}
                    alt="Waltz Roll Choko Hazelnut"
                  />
                </div>
                <div>
                  <img src={waltz_roll_slika2} alt="Waltz Roll Choko Milk" />
                </div>
                <div>
                  <img src={waltz_roll_slika3} alt="Waltz Roll Choko Cocoa" />
                </div>
                <div>
                  <img src={waltz_roll_slika4} alt="Waltz Roll Choko" />
                </div>
              </Swiper>
            </Link>
            <Link to="/waltz-cream" className="column is-6 padding-0">
              <Swiper {...swiperSlider1}>
                <div>
                  <img src={waltz_krem_kakao} alt="Waltz krem kakao" />
                </div>
                <div>
                  <img src={waltz_krem_mlecni} alt="Waltz krem mlečni" />
                </div>
                <div>
                  <img src={waltz_krem_mesavina} alt="Waltz krem mešavina" />
                </div>
                <div>
                  <img src={sweet_krem_kakao} alt="Sweet krem kakao" />
                </div>
                <div>
                  <img src={sweet_krem_mesavina} alt="Sweet krem mešavina" />
                </div>
              </Swiper>
            </Link>
            <Link to="/cream-rolls" className="column is-6 padding-0">
              <Swiper {...swiperSlider2}>
                <div>
                  <img src={cream_rolls_cokolada} alt="Cream rolls čokolada" />
                </div>
                <div>
                  <img src={cream_rolls_jagoda} alt="Cream rolls jagoda" />
                </div>
                <div>
                  <img src={cream_rolls_ljesnjak} alt="Cream rolls lješnjak" />
                </div>
                <div>
                  <img
                    src={cream_rolls_pomorandza}
                    alt="Cream rolls pomorandža"
                  />
                </div>
                <div>
                  <img src={cream_rolls_vanila} alt="Cream rolls vanila" />
                </div>
              </Swiper>
            </Link>
            <Link to="/cream" className="column is-6 padding-0">
              <Swiper {...swiperSlider3}>
                <div>
                  <img src={waltz_bakery_kakao} alt="Waltz bakery kakao" />
                </div>
                <div>
                  <img
                    src={waltz_bakery_mesavina1}
                    alt="Waltz bakery mešavina"
                  />
                </div>
                <div>
                  <img
                    src={waltz_bakery_mesavina}
                    alt="Waltz bakery mešavina"
                  />
                </div>
                <div>
                  <img src={sweet_bakery_kakao} alt="Sweet bakery kakao" />
                </div>
                <div>
                  <img
                    src={sweet_bakery_mesavina}
                    alt="Sweet bakery mešavina"
                  />
                </div>
              </Swiper>
            </Link>
            <Link to="waltz-roll" className="column is-6 padding-0">
              <Swiper {...swiperSlider4}>
                <div>
                  <img src={waltz_roll_jagoda} alt="Waltz roll jagoda" />
                </div>
                <div>
                  <img src={waltz_roll_cokolada} alt="Waltz roll čokolada" />
                </div>
                <div>
                  <img src={waltz_roll_kokos} alt="Waltz roll kokos" />
                </div>
                <div>
                  <img src={waltz_roll_ljesnjak} alt="Waltz roll lješnjak" />
                </div>
                <div>
                  <img src={waltz_roll_mlecni} alt="Waltz roll mlečni" />
                </div>
              </Swiper>
            </Link>
            <Link to="/choco-cream-rolls" className="column is-6 padding-0">
              <Swiper {...swiperSlider5}>
                <div>
                  <img
                    src={waltz_krem_rolls_cokoladni}
                    alt="Waltz rolls čokoladni"
                  />
                </div>
                <div>
                  <img src={waltz_krem_rolls_mlecni} alt="Waltz rolls mlečni" />
                </div>
                <div>
                  <img
                    src={waltz_krem_rolls_narandza}
                    alt="Waltz rolls narandža"
                  />
                </div>
                <div>
                  <img
                    src={waltz_krem_rolls_ljesnjak}
                    alt="Waltz rolls lješnjak"
                  />
                </div>
              </Swiper>
            </Link>
            <Link to="waltz-block" className="column is-6 padding-0">
              <Swiper {...swiperSlider6}>
                <div>
                  <img src={waltz_blok} alt="Waltz blok" />
                </div>
              </Swiper>
            </Link>
            <Link to="waltz-kore-za-tortu" className="column is-6 padding-0">
              <Swiper {...swiperSlider7}>
                <div>
                  <img src={waltz_kore_1} alt="Waltz kore za tortu" />
                </div>
                <div>
                  <img src={waltz_kore_2} alt="Waltz kore za tortu" />
                </div>
                <div>
                  <img src={waltz_kore_3} alt="Waltz kore za tortu" />
                </div>
              </Swiper>
            </Link>
            {/*<Link to="waltz-roll-mini" className="column is-6 padding-0">
              <Swiper {...swiperSlider8}>
                <div>
                  <img src={waltz_roll_mini_1} alt="Waltz roll mini" />
                </div>
                <div>
                  <img src={waltz_roll_mini_2} alt="Waltz roll mini" />
                </div>
                <div>
                  <img src={waltz_roll_mini_3} alt="Waltz roll mini" />
                </div>
                <div>
                  <img src={waltz_roll_mini_4} alt="Waltz roll mini" />
                </div>
              </Swiper>
            </Link>*/}
          </div>
        </div>
      </section>
    )
  }
}

export default Products
